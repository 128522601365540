import React from 'react';

import { Props } from './CompanyContacts.types';
import styles from './CompanyContacts.module.scss';

import AlphabetPicker from 'src/Components/AlphabetPicker';
import FiltersMenu from 'src/Components/FiltersMenu/FiltersMenu.component';
import Grid from 'src/Components/Grid';
import List from 'src/Components/List';
import { Element } from 'react-scroll';
import WorkplaceHeader from 'src/Components/WorkplaceHeader';
import { DISPLAY_MODE } from 'src/Redux/UserPreferences/Types';
import { Binocular } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useMediaQuery } from 'react-responsive';
import { responsiveQuery } from 'src/Utils/responsive';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { getCurrentCompany } from 'src/Redux/Companies/Selectors';
import { useSection } from 'src/Hooks/Section/useSection';

const CompanyContacts = ({ contacts, cardDisplayMode }: Props) => {
  const ItemRenderer = cardDisplayMode === DISPLAY_MODE.GRID ? Grid : List;
  const isMobile = useMediaQuery({
    query: responsiveQuery,
  });
  const selectedCompany = useSelector(getCurrentCompany);
  const titleAdditionalText = selectedCompany
    ? ` chez ${selectedCompany?.companyName} - ${selectedCompany?.companyAddress?.postalCode} (${selectedCompany?.siret}) `
    : '';
  const section = useSection();

  return (
    <div className={classnames(styles.container, { [styles.mobileContainer]: isMobile })}>
      {!isMobile && (
        <div className={styles.headerSection}>
          <WorkplaceHeader
            title={`trouver un interlocuteur ${titleAdditionalText}`}
            comment="Retrouver l'ensemble de vos interlocuteurs client"
            rightNode={<FiltersMenu />}
          />
        </div>
      )}
      {contacts.length ? (
        <div className={classnames(styles.contactBook, { [styles.mobileBook]: isMobile })}>
          <Element
            id="listScrollableContainer"
            name="listScrollableContainer"
            className={styles.contactList}
            onScroll={() => {
              section?.setScrollTop(
                document.getElementById('listScrollableContainer')?.scrollTop ?? 0
              );
            }}
          >
            <ItemRenderer items={contacts} />
          </Element>
          <div
            className={classnames(styles.alphabetPickerContainer, {
              [styles.mobileAlphabetPickerContainer]: isMobile,
            })}
          >
            <AlphabetPicker />
          </div>
        </div>
      ) : (
        <div>
          <Binocular />
          <div className={styles.noResult}>Aucun résultat pour interlocuteur</div>
        </div>
      )}
    </div>
  );
};

export default CompanyContacts;
